<template>
    <div class="mcontainer">
        <bread-crumb :items="breadcrumbs" />
        <h2 class="text-lg font-semibold color-dark my-4">Detail Pengajuan Sidang Skripsi</h2>

        <loading :loading="form.loading" spinner-class="justify-center">
            <form @submit.prevent="submit">
                <div class="card p-3">
                    <h5 class="color-dark font-semibold">*Isi form di bawah ini untuk pengajuan sidang skripsi</h5>
                    <div class="mt-4">
                        <div class="form-group">
                            <label class="font-semibold">Judul Bahasa Indonesia</label><br>
                            <input class="form-control" v-model="form.data.judul_id" type="text" value="" autocomplete="off" required>
                        </div>
                        <div class="form-group">
                            <label class="font-semibold">Judul Bahasa Inggris</label><br>
                            <input class="form-control" v-model="form.data.judul_en" type="text" value="" autocomplete="off" required>
                        </div>
                        <div class="form-group">
                            <label class="font-semibold">KTM</label><br>
                            <input-file :read-only="true" :placeholder="placeholders.ktm ? placeholders.ktm : 'Masukan kartu tanda mahasiswa'" @change="(val) => form.data.ktm = val" />
                        </div>
                        <div class="form-group">
                            <label class="font-semibold">Jurnal (Acuan dan minimal 1 Pembanding )</label><br>
                            <input-file :read-only="true" :placeholder="placeholders.jurnal ? placeholders.jurnal : 'Jurnal (Acuan dan minimal 1 Pembanding )'" @change="(val) => form.data.jurnal = val" />
                        </div>
                        <div class="form-group">
                            <label class="font-semibold">Dokumen Skripsi Lengkap</label><br>
                            <input-file :read-only="true" :placeholder="placeholders.dokumen_lengkap ? placeholders.dokumen_lengkap : 'Dokumen Skripsi Lengkap'" @change="(val) => form.data.dokumen_lengkap = val" />
                        </div>
                        <div class="form-group">
                            <label class="font-semibold">Presentasi ( PPT File)</label><br>
                            <input-file :read-only="true" :placeholder="placeholders.presentasi ? placeholders.presentasi : 'Presentasi ( PPT File )'" @change="(val) => form.data.presentasi = val" />
                        </div>
                        <div class="form-group">
                            <label class="font-semibold">Jurnal Lengkap Dengan Abstract ( INA-ENG)</label><br>
                            <input-file :read-only="true" :placeholder="placeholders.jurnal_abstract ? placeholders.jurnal_abstract : 'Jurnal Lengkap Dengan Abstract ( INA-ENG)'" @change="(val) => form.data.jurnal_abstract = val" />
                        </div>
                        <div class="form-group">
                            <label class="font-semibold">Transkrip Nilai</label><br>
                            <input-file :read-only="true" :placeholder="placeholders.transkrip_nilai ? placeholders.transkrip_nilai : 'Masukan Transkrip Nilai'" @change="(val) => form.data.transkrip_nilai = val" />
                        </div>
                        <div class="form-group">
                            <label class="font-semibold">Kartu Bimbingan </label><br>
                            <input-file :read-only="true" :placeholder="placeholders.kartu_bimbingan ? placeholders.kartu_bimbingan : 'Masukan kartu bimbingan ( minimal 8x bimbingan )'" @change="(val) => form.data.kartu_bimbingan = val" />
                        </div>
                        <div class="form-group">
                            <label class="font-semibold">Bukti QR Code</label><br>
                            <input-file :read-only="true" :placeholder="placeholders.bukti_qr ? placeholders.bukti_qr : 'Masukkan QR Code Dosen'" @change="(val) => form.data.bukti_qr = val" />
                        </div>
                        <div class="form-group">
                            <label class="font-semibold">English Score</label><br>
                            <input-file :read-only="true" :placeholder="placeholders.english_score ? placeholders.english_score : 'English Score dari ULS'" @change="(val) => form.data.english_score = val" />
                        </div>
                        <div class="form-group">
                            <label class="font-semibold">Pembayaran UPP</label><br>
                            <input-file :read-only="true" :placeholder="placeholders.pembayaran_upp ? placeholders.pembayaran_upp : 'Pembayaran UPP s/d bulan berjalan.'" @change="(val) => form.data.pembayaran_upp = val" />
                        </div>
                        <div class="form-group">
                            <label class="font-semibold">Hasil Turnutin</label><br>
                            <input-file :read-only="true" :placeholder="placeholders.hasil_turnutin ? placeholders.hasil_turnutin : 'Hasil Turnitin. Maks 29%'" @change="(val) => form.data.hasil_turnutin = val" />
                        </div>

                        <div class="flex justify-end pt-4">
                            <a @click.prevent="openModalReschedule" class="btn btn-outline-primary py-2">
                                Tolak
                            </a>
                            <button @click.prevent="accept" type="button" class="lg:px-5 rounded-md bg-blue-600 text-white space-x-1.5 px-5 ml-3">
                                Terima
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </loading>

        <modal name="modal-reschedule" :height="430" :width="600">
            <div class="p-5">
                <div class="d-flex justify-content-end align-items-center">
                    <a href="" @click.prevent="closeModalReschedule">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM14.3 14.3C14.2075 14.3927 14.0976 14.4663 13.9766 14.5164C13.8557 14.5666 13.726 14.5924 13.595 14.5924C13.464 14.5924 13.3343 14.5666 13.2134 14.5164C13.0924 14.4663 12.9825 14.3927 12.89 14.3L10 11.41L7.11 14.3C6.92302 14.487 6.66943 14.592 6.405 14.592C6.14057 14.592 5.88698 14.487 5.7 14.3C5.51302 14.113 5.40798 13.8594 5.40798 13.595C5.40798 13.4641 5.43377 13.3344 5.48387 13.2135C5.53398 13.0925 5.60742 12.9826 5.7 12.89L8.59 10L5.7 7.11C5.51302 6.92302 5.40798 6.66943 5.40798 6.405C5.40798 6.14057 5.51302 5.88698 5.7 5.7C5.88698 5.51302 6.14057 5.40798 6.405 5.40798C6.66943 5.40798 6.92302 5.51302 7.11 5.7L10 8.59L12.89 5.7C12.9826 5.60742 13.0925 5.53398 13.2135 5.48387C13.3344 5.43377 13.4641 5.40798 13.595 5.40798C13.7259 5.40798 13.8556 5.43377 13.9765 5.48387C14.0975 5.53398 14.2074 5.60742 14.3 5.7C14.3926 5.79258 14.466 5.90249 14.5161 6.02346C14.5662 6.14442 14.592 6.27407 14.592 6.405C14.592 6.53593 14.5662 6.66558 14.5161 6.78654C14.466 6.90751 14.3926 7.01742 14.3 7.11L11.41 10L14.3 12.89C14.68 13.27 14.68 13.91 14.3 14.3Z" fill="currentColor"/>
                        </svg>
                    </a>
                </div>
                <div>
                    <div>
                        <h5 class="text-black font-semibold dark:text-gray-100 text-lg">
                            Note
                        </h5>
                        <p>Masukkan alasan kenapa kamu menolak sidang skripsi ini</p>
                        <textarea v-model="formRechedule.data.note" class="form-control mt-4" placeholder="Tuliskan alasan kamu di sini" cols="30" rows="5"></textarea>
                    </div>
                    <div class="flex justify-center items-center">
                        <button class="btn btn-primary search-button mt-3" @click.prevent="reschedule">
                            <span v-if="formRechedule.loading"><b-spinner small label="Loading..." /></span>
                            Kirim
                        </button>
                    </div>
                </div>
            </div>
        </modal>

    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import InputFile from '@/components/InputFile.vue'
    import BreadCrumb from '@/components/BreadCrumb.vue'

    export default {
        components: { InputFile, BreadCrumb },
        computed: mapState('skripsi', ['data', 'loading']),
        data () {
            return {
                formRechedule: {
                    loading: false,
                    data: {
                        note: ''
                    }
                },
                form: {
                    show: false,
                    data: {
                        id: '',
                        judul_id: '',
                        judul_en: '',
                        ktm: null,
                        jurnal: null,
                        dokumen_lengkap: null,
                        presentasi: null,
                        jurnal_abstract: null,
                        transkrip_nilai: null,
                        kartu_bimbingan: null,
                        bukti_qr: null,
                        english_score: null,
                        pembayaran_upp: null,
                        hasil_turnutin: null,
                        note: '',
                        date: ''
                    },
                    loading: false
                },
                placeholders: {
                    ktm: '',
                    jurnal: '',
                    dokumen_lengkap: '',
                    presentasi: '',
                    jurnal_abstract: '',
                    transkrip_nilai: '',
                    kartu_bimbingan: '',
                    bukti_qr: '',
                    english_score: '',
                    pembayaran_upp: '',
                    hasil_turnutin: ''
                },
                breadcrumbs: [
                    {
                        route: 'Skripsi',
                        label: 'Skripsi',
                        active: true
                    },
                    {
                        route: 'MahasiswaBimbingan',
                        label: 'Detail Pengajuan sidang skripsi'
                    }
                ]
            }
        },
        methods: {
            openModalReschedule () {
                this.$modal.show('modal-reschedule')
            },
            closeModalReschedule () {
                this.$modal.hide('modal-reschedule')
                this.formRechedule.data.note = ''
            },
            submit () {
                this.form.loading = true
                this.$store.dispatch('skripsi/createSidang', this.form.data)
                    .then((res) => {
                        console.log(res)
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                    .finally(() => {
                        this.form.loading = false
                    })
            },
            getFileName (url) {
                return url.split('/').pop()
            },
            ...mapActions('skripsi', { fetch: 'fetch', fetchSidang: 'showSidang', updateSidang: 'updateSidang' }),
            accept () {
                this.form.loading = true
                this.updateSidang({ id: this.$route.params.id, data: { status: 2 } })
                    .then(() => {
                        this.$swal({
                            imageUrl: require('@/assets/images/sidang-accept.png'),
                            imageWidth: 400,
                            title: 'Pengajuan Diterima',
                            text: 'Terimakasih telah menyetujui sidang skripsi'
                        })
                        this.$router.push({ name: 'AdminPengajuanSkripsi' })
                    })
                    .catch(() => {
                        this.$swal({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something Wrong'
                        })
                    })
                    .finally(() => {
                        this.form.loading = false
                    })
            },
            reschedule () {
                this.formRechedule.loading = true
                this.updateSidang({
                    id: this.$route.params.id,
                    data: {
                        note: this.formRechedule.data.note,
                        status: 3
                    }
                }).then(() => {
                    this.$swal({
                        imageUrl: require('@/assets/images/bimbingan-approved.png'),
                        imageWidth: 400,
                        title: 'Jadwal Ditolak',
                        text: 'Terima kasih telah menolak sidang skripsi'
                    })
                    this.$router.push({ name: 'AdminPengajuanSkripsi' })
                }).catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something Wrong'
                    })
                }).finally(() => {
                    this.formRechedule.loading = false
                })
            },
            fetchPengajuanSidang () {
                this.form.loading = true
                this.fetchSidang({ id: this.$route.params.id })
                    .then(res => {
                        this.form.data.id = res.id
                        this.form.data.judul_id = res.judul_id
                        this.form.data.judul_en = res.judul_en
                        this.placeholders.ktm = this.getFileName(res.ktm)
                        this.placeholders.jurnal = this.getFileName(res.jurnal)
                        this.placeholders.dokumen_lengkap = this.getFileName(res.dokumen_lengkap)
                        this.placeholders.presentasi = this.getFileName(res.presentasi)
                        this.placeholders.jurnal_abstract = this.getFileName(res.jurnal_abstract)
                        this.placeholders.transkrip_nilai = this.getFileName(res.transkrip_nilai)
                        this.placeholders.kartu_bimbingan = this.getFileName(res.kartu_bimbingan)
                        this.placeholders.bukti_qr = this.getFileName(res.bukti_qr)
                        this.placeholders.english_score = this.getFileName(res.english_score)
                        this.placeholders.pembayaran_upp = this.getFileName(res.pembayaran_upp)
                        this.placeholders.hasil_turnutin = this.getFileName(res.hasil_turnutin)
                    })
                    .finally(() => {
                        this.form.loading = false
                    })
            }
        },
        mounted () {
            this.fetchPengajuanSidang()
        }
    }
</script>
